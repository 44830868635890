<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-3xl">
      <checkbox-list
        :options="data.options"
        option-value="title"
        option-key="title"
        v-model="data.model"
        class="pt-8 sm:grid sm:grid-cols-2 gap-x-4" />

      <div
        v-if="data.meta.required && data.model.includes(data.meta.comparator)"
        class="text-left pt-12">
        <input-textarea
          ref="meta"
          autofocus
          v-model="data.meta.model"
          :label="data.meta.title" />
      </div>
    </div>

    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    metaModelErrors () {
      return validate.single(this.data.meta.model, {
        presence: true,
        length: {
          minimum: 2,
          message: "must be at least 2 characters"
        }
      })
    },

    modelErrors () {
      return validate.single(this.data.model, {
        presence: true,
        type: "array",
        length: {
          minimum: 1
        }
      })
    },

    isValid () {
      if (this.data.validationRules && this.data.validationRules.filter(rule => rule.type !== "requireAll").length > 0) {
        if (this.data.model.length !== this.data.options.length) {
          return false
        }
      }

      if (this.data.meta.required && this.data.model.includes(this.data.meta.comparator)) {
        return (typeof this.modelErrors === "undefined") && (typeof this.metaModelErrors === "undefined")
      }

      return (typeof this.modelErrors === "undefined")
    }
  },

  watch: {
    "data.model" () {
      this.$nextTick(() => {
        if (this.data.meta.required && this.data.model.includes(this.data.meta.comparator)) {
          this.$refs.meta.$el.scrollIntoView({behavior: "smooth"})
        }
      })
    }
  }
}
</script>
